import React from "react"
import fastEntryStyles from './fast-entry-a4.module.css'

export default class FastEntryA4 extends React.Component{
  render() {
    return(
      <div className={fastEntryStyles.fastEntryContainer}>
        <div className={fastEntryStyles.top}>
          <div>
            <div className={fastEntryStyles.enrollIcon}></div>
            {/*<img src={require('../images/a3/enroll.png')} alt="报名"/>*/}
            <div className={fastEntryStyles.text}>报名</div>
          </div>

        </div>
        <div className={fastEntryStyles.bottom}>
          <div>
            <div className={fastEntryStyles.numIcon}></div>
            {/*<img src={require('../images/a3/race_num.png')} alt="参赛号"/>*/}
            <div className={fastEntryStyles.text}>参赛号</div>
          </div>
        </div>
      </div>
    )
  }
}