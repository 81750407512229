import React from "react"
import FastEntryA4 from "./fast-entry-a4"
import layoutMainStyles from './layout-a4-main.module.css'
import CountDownA4 from "./countdown-a4"
import SupporterA4 from './supporters-a4'

export default  class LayoutA4Main extends React.Component {
  render() {
    const {  children } = this.props
    return(
      <div className={layoutMainStyles.mainContainer}>
        <div className={layoutMainStyles.topHalf}>
          <div className={layoutMainStyles.topHalfLeft}>
            <FastEntryA4 />
            <CountDownA4 count={1000}/>
          </div>
          <div className={layoutMainStyles.topHalfRight}>
            <main>{children}</main>
          </div>
        </div>
        <div className={layoutMainStyles.bottomHalf}>
          <SupporterA4 />
        </div>
      </div>
    )
  }
}