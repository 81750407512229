import React from 'react'
import countdownA4 from './countdown-a4.module.css'
import PropTypes from "prop-types"

export default class CountDownA4 extends React.Component{
  static propTypes={
    count:PropTypes.number.isRequired
  }
  render() {
    let days=Math.floor(this.props.count / 60 % 60)
    let hours=Math.floor(this.props.count / 60 % 60)
    let minutes = Math.floor(this.props.count / 60 % 60)
    let seconds = Math.floor(this.props.count % 60)
    return(
      <div className={countdownA4.countDownContainer}>
        <div className={countdownA4.title}>
          <div className={countdownA4.titleLeft}></div>
          <div className={countdownA4.titleText}>比赛倒计时</div>
          <div className={countdownA4.titleRight}></div>
        </div>
        <div className={countdownA4.timeContainer}>
          <div className={countdownA4.day}>
            <div className={countdownA4.time}>
              <div>{days}</div>
            </div>
            <div className={countdownA4.text}>天</div>
          </div>
          <div className={countdownA4.colon}>:</div>
          <div className={countdownA4.hour}>
            <div className={countdownA4.time}>
              <div>{hours}</div>
            </div>
            <div className={countdownA4.text}>小时</div>
          </div>
          <div className={countdownA4.colon}>:</div>
          <div className={countdownA4.minute}>
            <div className={countdownA4.time}>
              <div>{minutes}</div>
            </div>
            <div className={countdownA4.text}>分</div>
          </div>
          <div className={countdownA4.colon}>:</div>
          <div className={countdownA4.second}>
            <div className={countdownA4.time}>
              <div>{seconds}</div>
            </div>
            <div className={countdownA4.text}>秒</div>
          </div>
        </div>
      </div>
    )
  }
}