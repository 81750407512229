import React from "react"
import LayoutA4 from "../../components/layout-a4"
import  LayoutA4Main from '../../components/layout-a4-main'
import enrollStyles from './a4_enroll.module.css'
import { navigate } from "@reach/router"

export default class A4Enroll extends React.Component {

  chooseEvent=()=>{
    navigate('/demos/themeA4/enrollStep')
  }


  render() {
    const  raceDetail={
      raceName:'2019上海静安国际女子马拉松',
      poster:'../../images/a2/3.png',
      startTime:'2019-08-16 10:00:00',
      endTime:'2019-10-16 10:00:00',
      raceTime:'2019-11-16 7:00:00',
      location:'上海市',
      events:[{name:"全程马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"},
        {name:"半程马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"},
        {name:"迷你马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"}]

    }
    const color='#7e5695'

    return(
      <LayoutA4>
        <LayoutA4Main>
        <div className={enrollStyles.enrollContainer}>
          {/*{this.state.step===0&&*/}
          <div>
            <div className={enrollStyles.raceInfoContainer}>
              <div className={enrollStyles.racePoster}>
                <img alt="hello" src={require('../../images/a2/3.png')}/>
              </div>
              <div className={enrollStyles.raceInfo}>
                <div className={enrollStyles.raceTitle}>{raceDetail.raceName}</div>
                <div className={enrollStyles.startTime}>报名开始：{raceDetail.startTime}</div>
                <div className={enrollStyles.endTime}>报名截止：{raceDetail.endTime}</div>
                <div className={enrollStyles.raceTime}>比赛开始：{raceDetail.raceTime}</div>
                <div className={enrollStyles.raceLocation}>赛事地点：{raceDetail.location}</div>
              </div>
            </div>


            <div className={enrollStyles.eventsContainer}>
                {raceDetail.events.map((event,index)=><div className={enrollStyles.eventItem} key={index}>
                  <div style={{flex:'1'}}>
                    <div className={enrollStyles.eventName}>
                      <div className={enrollStyles.name}>{event.name}</div>
                      <div className={enrollStyles.price} style={{color:color}}>报名费：{event.price}</div>
                    </div>
                    <div className={enrollStyles.eventTip}>{event.tip}</div>
                  </div>
                  <div className={enrollStyles.eventEnrollBtn} onClick={this.chooseEvent}>立即报名</div>
                  <div className={enrollStyles.pricePhone} style={{color:color}} onClick={this.chooseEvent}>￥{event.price}</div>
                </div>)}
              </div>
          </div>
          {/*// }*/}
          {/*// {*/}
          {/*//   this.state.step>0&&<EnrollStep color={color} step={this.state.step} addStep={this.addStep} deleteStep={this.deleteStep}/>*/}
          {/*// }*/}
        </div>
        </LayoutA4Main>
      </LayoutA4>

    )
  }
}